<template>
  <footer
    class="overflow-hidden rounded-xl bg-gradient-to-r from-stone-900 to-stone-800"
  >
    <Newsletter v-if="isHome" />
    <div class="border-b border-stone-700">
      <div class="border-stone-700 text-white">
        <div class="select-none grid-cols-4 gap-0 md:grid">
          <div
            class="mb-6 flex flex-col justify-evenly space-y-4 px-10 py-8 md:order-4 md:mb-0"
          >
            <div class="flex flex-row justify-center">
              <div class="flex-0 pr-6 text-2xl">
                <UIcon name="i-fa6-solid-leaf" />
              </div>
              <div class="flex-1">
                <p class="m-0 font-bold">Carbon Neutrality</p>
              </div>
            </div>

            <div class="flex flex-row justify-center">
              <div class="flex-0 pr-6 text-2xl">
                <UIcon name="i-fa6-solid-earth-americas" />
              </div>
              <div class="flex-1">
                <p class="m-0 font-bold">Worldwide Shipping</p>
              </div>
            </div>

            <div class="flex flex-row justify-center">
              <div class="flex-0 pr-6 text-2xl">
                <UIcon name="i-fa6-solid-shield-halved" />
              </div>
              <div class="flex-1">
                <p class="m-0 font-bold">Secure Payments</p>
              </div>
            </div>

            <div class="flex flex-row justify-center">
              <div class="flex-0 pr-6 text-2xl">
                <UIcon name="i-fa6-solid-circle-question" />
              </div>
              <div class="flex-1">
                <p class="m-0 font-bold">Super Service</p>
              </div>
            </div>
          </div>

          <div
            class="order-2 border-b border-stone-700 px-5 py-8 md:border-b-0 md:border-r md:px-10"
          >
            <p
              class="am-heading mb-3 select-none text-sm font-bold tracking-wide"
            >
              Shop
            </p>
            <ul class="space-y-2">
              <li
                v-for="item in mainMenu"
                :key="item.label"
                class="flex items-center pr-3 font-normal tracking-wide last:mb-0"
              >
                <nuxt-link
                  :to="item.to"
                  class="opacity-75 transition-colors transition-opacity hover:text-red-500 hover:opacity-100"
                  >{{ item.label }}</nuxt-link
                >
              </li>
            </ul>
          </div>
          <div
            class="order-3 border-b border-stone-700 px-5 py-8 lg:border-b-0 lg:border-r lg:px-10"
          >
            <p
              class="am-heading mb-3 select-none text-sm font-bold tracking-wide"
            >
              Information
            </p>
            <ul class="space-y-2">
              <li
                v-for="item in infoMenu"
                :key="item.label"
                class="flex items-center pr-3 font-normal tracking-wide last:mb-0"
              >
                <nuxt-link
                  :to="item.to"
                  class="opacity-75 transition-colors transition-opacity hover:text-red-500 hover:opacity-100"
                  >{{ item.label }}</nuxt-link
                >
              </li>
            </ul>
          </div>

          <div class="flex flex-col border-stone-700 lg:order-1 lg:border-r">
            <nuxt-link
              to="/"
              class="group relative flex flex-1 flex-col items-center justify-center text-center text-stone-300 transition-colors hover:text-white"
            >
              <div
                class="absolute inset-0 z-0 bg-[length:140%_auto] bg-center bg-no-repeat shadow-inner grayscale transition-all group-hover:grayscale-0"
                :class="['bg-[url(\'' + logoBgImage + '\')]']"
                :style="{
                  backgroundImage: 'url(' + logoBgImage + ')',
                }"
              />

              <div class="block w-32 py-12">
                <BrandLogo class="full block drop-shadow-lg" />
              </div>
            </nuxt-link>

            <div class="flex-0 border-t border-stone-700 px-3 py-6 text-center">
              <div class="flex flex-row justify-around">
                <UButton
                  v-for="account in socialAccountData"
                  :key="account.name"
                  :icon="account.icon"
                  size="lg"
                  :to="account.url"
                  square
                  target="_blank"
                  color="white"
                  variant="link"
                  class="hover:text-primary-500 transition-colors"
                  :title="account.name + ' (' + account.handle + ')'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <div class="flex select-none border-gray-700 p-5 lg:px-8">
        <div class="flex flex-1 flex-col justify-center">
          <p class="text-sm font-bold tracking-wide text-gray-500">
            Copyright &#169; {{ copyrightYears }}
          </p>
        </div>
        <div v-if="currentBreakpoint">Size: {{ currentBreakpoint }}</div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { format, addYears } from "date-fns";
import logoBgImage from "@/assets/img/header-bg-03.png";

const route = useRoute();
const store = useStore();
const { buildDate } = useRuntimeConfig().public;
const { shop, mainMenu, infoMenu, currentBreakpoint, socialAccountData } =
  storeToRefs(store);

const currentDate = ref(new Date());
const isHome = computed(() => route.name == "index");

const lastBuildString = computed(() => {
  return "Last updated: " + parseISO(buildDate);
});

const copyrightYears = computed(
  () =>
    format(currentDate.value, "yyyy") +
    "-" +
    format(addYears(currentDate.value, 1), "yyyy")
);
</script>

<style scoped></style>
