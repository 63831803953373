<template>
  <div class="border-b border-stone-700">
    <div
      class="container mx-auto border-stone-700 py-12 text-white  lg:py-20"
    >
      <div class="grid-cols-2 items-center gap-8 lg:grid">
        <div class="mb-8 lg:mb-0">
          <p
            class="am-heading mb-1 select-none text-lg font-bold lg:mb-3  lg:text-xl"
          >
            Sign up &amp; Save
          </p>

          <p
            class="text-md font-thin tracking-wide text-white lg:text-xl"
          >
            Join our email list for exclusive offers and the latest news.
          </p>
        </div>
        <div><NewsletterSignupForm /></div>
      </div>
    </div>
  </div>
</template>
