<template>
  <div>
    <div v-if="!isSubmitted">
      <UForm
        ref="formRef"
        :state="state"
        class="mb-4"
        @submit="onSubmit"
      >
        <UFormGroup name="EMAIL">
          <FormInput
            ref="emailRef"
            v-model="state.email"
            type="email"
            autocomplete="email"
            size="xl"
            color="white"
            variant="outline"
            icon="i-fa6-regular-envelope"
            placeholder="Enter your email"
          >
            <FormInputButton color="white">
              Submit
            </FormInputButton>
          </FormInput>
        </UFormGroup>

        <div style="position: absolute; left: -5000px">
          <input
            type="text"
            name="b_53b96d14af01290421fde4aab_390980795d"
            tabindex="-1"
            value=""
          >
        </div>
      </UForm>
    </div>
    <div
      v-else
      class="text-white"
    >
      Thanks for submitting!
    </div>
  </div>
</template>

<script setup lang="ts">
import fetchJsonp from "fetch-jsonp"
import { LoadingState } from "@/enums"

const loadingState = ref(LoadingState.Idle)

const isSubmitted = ref(false)

const formRef = ref(null)
const emailRef = ref(null)

const defaultParams = {
  u: "53b96d14af01290421fde4aab",
  id: "390980795d",
  f_id: "00ce5ee0f0",
}

const state = reactive({
  email: "",
})

const query = computed(
  () => new URLSearchParams({ ...defaultParams, ...state }),
)

const onValidate = (state: any) => {}

const onSubmit = async () => {
  try {
    isSubmitting.value = true
    const response = await fetchJsonp(
      `https://astronomerch.us8.list-manage.com/subscribe/post-json?${query.value.toString()}`,

      {
        jsonpCallback: "c",
      },
    )

    const { result, msg } = await response.json()

    if (result == "success") {
      isSubmitting.value = false
      isSubmitted.value = true
      await sleep(3000)
      email.value = ""
      isSubmitted.value = false
    }
    else {
      // Handle error state
      isSubmitting.value = false
      emailEl.value.focus()
    }
  }
  catch (error) {
    isSubmitting.value = false
    isSubmitted.value = false
  }
  //   isSubmitted.value = true;

  //   await new Promise((r) => setTimeout(r, 2000));
}
</script>
